import React, {useState} from "react";
import {RSVPButton} from "./RSVP.jsx";
import Link from "@mui/material/Link";
import {
    CardContent,
    Chip,
    Dialog,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme
} from "@mui/material";
import ReadMoreTwoToneIcon from '@mui/icons-material/ReadMoreTwoTone';
import Card from "@mui/material/Card";
import {venueMap} from "../shared/venue.js";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import {display24HourTime} from "../shared/date.js";
import {InstagramEmbed} from "react-social-media-embed";
import InstagramIcon from '@mui/icons-material/Instagram';
import CancelIcon from '@mui/icons-material/Cancel';

export const copyToClipboard = (e, input, user) => {
    e.preventDefault();
    navigator.clipboard.writeText(input);
    user.setSnackBar({open: true, message: "Copied to Clipboard"})
}

const eventPosters = {
    "2025-03-08-atx-sams-town-point" : {
        title: "Samsville SX Kickoff Party",
        url: "https://www.instagram.com/p/DGi2qqEJR44/?hl=en"
    },
}

export const Event = (props) => {
    const {venue, rsvpDate, user} = props;
    let [detailOpen, setDetailOpen] = useState(false);
    let [posterOpen, setPosterOpen] = useState(false);

    const link = venue.data.reduce((acc, slot) => {
        if (slot.link !== undefined) {
            acc = slot.link
        }
        return acc;
    }, false)

    const rsvpKey = `${rsvpDate}-${venue.venueKey}`
    const count = user.rsvpCount.hasOwnProperty(rsvpKey) ? <sup>{user.rsvpCount[rsvpKey]}</sup> : "";


    return  <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                <div>
                    <span style={{fontSize: "125%"}}>{venue.venue}</span>
                    {user.loggedIn && <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                venueKey={venue.venueKey}/>}
                    {count}
                    <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "0px"}} variant={"contained"} href={`/event/${venue.venueKey}/${rsvpDate}`}><ReadMoreTwoToneIcon color={"primary"} /></IconButton>
                    {link && <div><Link href={link} target={"_blank"}>{link}</Link></div>}
                </div>
                <div>
                    {eventPosters.hasOwnProperty(rsvpKey) && <Button onClick={()=>setPosterOpen(true)} startIcon={<InstagramIcon />}>{eventPosters[rsvpKey].title}</Button>}
                    {eventPosters.hasOwnProperty(rsvpKey) && posterOpen && <Dialog fullScreen open={true}>
                        <Button startIcon={<CancelIcon />} onClick={()=>setPosterOpen(false)}>Close</Button>
                        <InstagramEmbed url={eventPosters[rsvpKey].url}/>
                    </Dialog>}
                    {venue.data.map((slots, i) => <div
                        key={i}><strong>{display24HourTime(slots.time)} </strong>{slots.danceable ?
                        <strong><Link
                            href={"/artist/" + slots.bandKey}>{slots.band}</Link></strong> : slots.band} {slots.venmo &&
                        <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})}
                              size={"small"} color={"info"} label={"tip"} component={"a"}
                              clickable/>}</div>)}
                    {venue.isOwner && <Button startIcon={<EditIcon />} size={"small"} href={"/event/edit?groupUUID=" + venue.groupUUID}>Edit</Button>}
                </div>
                {detailOpen && <EventDetail {...props} setDetailOpen={setDetailOpen}/>}
            </div>
}


export const EventDetail = (props) => {
    const {venue, rsvpDate, user, date, setDetailOpen} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const vKey = venue.venueKey;
    const v = venueMap[vKey];


    return <Dialog open={true} fullScreen={fullScreen} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>
                <div style={{float: "right"}}><Button color={"primary"} variant={"contained"} onClick={() => setDetailOpen(false)}><CloseIcon /></Button></div>
                <div><em>{venue.venue}</em></div>
            </DialogTitle>
            <Card>
                <CardContent>
                    <h5>Lineup for {date}</h5>
                    <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                        <div>
                            {user.loggedIn && <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                                          venueKey={venue.venueKey}/>}
                        </div>
                        <div>
                            {venue.data.map((slots, i) => <div
                                key={i}><strong>{display24HourTime(slots.time)}</strong> {slots.danceable ?
                                <strong>
                                    <Link href={"/artist/" + slots.bandKey}>{slots.band}</Link>
                                </strong> : slots.band}
                                {slots.tag && <span> <i>{slots.tag}</i> </span>}
                                {slots.venmo &&
                                    <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})}
                                          size={"small"} color={"info"} label={"tip"} component={"a"}
                                          clickable/>}</div>)}
                        </div>
                        <h5>Details</h5>
                        <div><small>Location: {v.location}</small></div>
                        <div><small>Cover: {v.cover}</small></div>
                        <div><small>Ages: {v.age}</small></div>
                    </div>
                </CardContent>
            </Card>
        </Dialog>
}

